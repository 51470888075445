@if(!loading){
<ul class="layout-menu">
  <li
    app-menuitem
    *ngFor="let item of model; let i = index"
    [item]="item"
    [index]="i"
    [root]="true"
    [ngClass]="test"
  ></li>
</ul>

} @else {
<div class="spinner-container-menu">
  <ion-spinner name="dots"> </ion-spinner>
</div>
}
