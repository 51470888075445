<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>

<ion-grid class="full-grid">
  <ion-row>
    <div class="header">
      <div class="back-button-container">
        <div class="back-btn">
          <p-button
            (click)="goBack()"
            icon="pi pi-arrow-left"
            [outlined]="true"
            label="Volver"
            [style]="{ 'color': 'white', 'border-color': 'white' }"
          >
          </p-button>
        </div>
      </div>
    </div>
  </ion-row>

  <!-- LOADER -->
  @if(loading){
  <div class="spinner-container-indicator">
    <ion-spinner name="dots"> </ion-spinner>
    <ion-label>Cargando datos del indicador, por favor espere...</ion-label>
  </div>
  }
  <!--  -->
  @if(!loading){
  <ion-row>
    <ion-col
      size-xs="12"
      size-sm="12"
      size-md="12"
      class="margin-col-start margin-col-end"
    >
      <div class="card basicCard">
        <ion-grid class="left-grid">
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="4">
              @if(user.id === 1){
                <div>
                  <p-speedDial 
                    [model]="itemsOptions" 
                    direction="right"  
                    showIcon="pi pi-cog" 
                    hideIcon="pi pi-times" 
                  />
                </div>
              }
            </ion-col>
            <ion-col
              class="circle-col-center"
              size-xs="12"
              size-sm="12"
              size-md="4"
            >
              <!-- Círculo central -->
              <div class="circle">
                <span style="font-size: 60px; font-weight: bold"
                  >{{labelIcon}}</span
                >
              </div>
            </ion-col>
            <ion-col
              class="circle-col-end"
              size-xs="12"
              size-sm="12"
              size-md="4"
            >
              @if(permissionService.checkSectionPermissions(userByCompany, 17,
              [1])){
              <p-button
                label="Generar informe Word"
                (click)="checkExistsGraphic($event)"
                [loading]="loadingGenerateWord"
              ></p-button>
              }
            </ion-col>
          </ion-row>
        </ion-grid>

        <div class="img-container">
          @for(icon of indicator?.icon; track icon){
          <img [src]="icon?.icon" alt="" />
          }
        </div>

        <div class="efrag-container">
          <img
            src="https://demo.app.esg.icloudcompliance.com/imgs/efragLogo.png"
            alt=""
          />
        </div>

        <ion-row>
          <ion-col>
            <h3>{{ indicatorName }}</h3>
          </ion-col>
        </ion-row>
        
        <!-- Descripción del indicador -->
        @if(indicator.description){
          <div class="users-container">
            <div class="users-grid">
              <p>{{ indicator.description }}</p>
            </div>
          </div>
        }
      </div>
    </ion-col>
  </ion-row>

  <ion-row>
    <!-- Objetivos -->
    <ion-col size-xs="12" size-sm="12" size-md="6" class="margin-col-start">
      <div class="card" style="margin-top: 30px">
        <div class="flex align-items-center justify-content-center">
          <p-button
            class="m-1"
            label="Añadir objetivo"
            icon="pi pi-plus"
            [loading]="loadingObjective"
            (click)="showAddObjectiveDialog()"
          ></p-button>
        </div>
        @if(objectiveData.length > 0 && (user?.id === 1 || showObjectives ===
        'ALLOWED')){
        <p-dataView #dv [value]="objectiveData" [rows]="5" [paginator]="true">
          <ng-template pTemplate="list" let-objectiveData>
            @for(item of objectiveData; track table; let i = $index){
            <ion-item lines="none" class="item-list">
              <ion-label
                >Objetivo con fecha {{item?.date | date:
                'dd/MM/YYYY'}}</ion-label
              >
              <p-button
                icon="pi pi-eye"
                pTooltip="Ver objetivo"
                class="p-1"
                (click)="showViewObjective(item.id)"
              ></p-button>
              <p-button
                icon="pi pi-pencil"
                pTooltip="Editar objetivo"
                class="p-1"
                (click)="showEditObjectiveDialog(item.id)"
              ></p-button>
            </ion-item>
            }
          </ng-template>
        </p-dataView>
        }
        <!--  -->
        @if(user?.id !== 1 && showObjectives === 'NOT_ALLOWED') {
        <div class="flex justify-content-center align-items-center">
          No tiene permisos de lectura de objetivos
        </div>
        } @if(objectiveData.length === 0) {
        <div class="flex justify-content-center align-items-center m-4">
          No existen objetivos
        </div>
        }
      </div>
    </ion-col>

    <!-- Estado inicial -->
    <ion-col
      size-xs="12"
      size-sm="12"
      size-md="6"
      class="margin-col-start margin-col-end"
    >
      <div class="card" style="margin-top: 30px">
        <div class="flex align-items-center justify-content-center">
          @if(initialStateData === null){
          <p-button
            class="m-1"
            label="Añadir estado inicial"
            icon="pi pi-plus"
            [loading]="loadingInitialState"
            (click)="showAddInitialStateDialog(1)"
          ></p-button>
          }@else if(initialStateData && !initialStateData.data){
          <p-button
            class="m-1"
            label="Completar estado inicial"
            icon="pi pi-plus"
            [loading]="loadingInitialState"
            (click)="showAddInitialStateDialog(2)"
          ></p-button>
          }@else if(initialStateData && initialStateData?.data &&
          buttonEditInitialState){
          <p-button
            class="m-1"
            label="Editar estado inicial"
            icon="pi pi-plus"
            [loading]="loadingInitialState"
            (click)="showEditInitialStateDialog()"
          ></p-button>
          }
        </div>
        @if(showInitialState === 'ALLOWED' && initialStateData?.data){
        <ion-item lines="none" class="item-list">
          <ion-label
            >Estado inicial con fecha {{initialStateData?.date | date:
            'dd/MM/YYYY'}}</ion-label
          >
          <p-button
            icon="pi pi-eye"
            pTooltip="Ver estado inicial"
            class="p-1"
            (click)="showViewInitialState()"
          ></p-button>
        </ion-item>
        }
        <!--  -->
        @if(user?.id !== 1 && showInitialState === 'NOT_ALLOWED') {
        <div class="flex justify-content-center align-items-center m-4">
          No tiene permisos de lectura de estado inicial
        </div>
        }
        <!--  -->
        @if(!initialStateData) {
        <div class="flex justify-content-center align-items-center m-4">
          No existe estado inicial
        </div>
        }
      </div>
    </ion-col>
  </ion-row>

  <!-- Última evidencia ==> tabla al completo aquí -->
  <ion-row>
    <ion-col
      size-xs="12"
      size-sm="12"
      size-md="12"
      class="margin-col-start margin-col-end"
    >
      <div class="card basicCard flex" style="margin-top: 3vh">
        <ion-grid style="width: 100%">
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="12">
              <div class="container-title">
                Última evidencia ({{ latestEvidence.created_at | date: 'dd/MM/YYY' }})
              </div>
              <ion-grid>
                <ion-row>
                  <ion-col size-xs="12" size-sm="12" size-md="12" class="margin-col-start">
                    @if(latestEvidence){
                      <table>
                        <tbody>
                          <tr *ngFor="let row of latestEvidence?.data?.rows">
                            <ng-container *ngFor="let cell of row.cells">
                              <td
                                [attr.colspan]="cell.colspan"
                                [attr.rowspan]="cell.rowspan"
                                [class.vertical-text]="cell['vertical-text']"
                                [ngStyle]="getCellStyle(cell)"
                              >
                                <div [ngStyle]="getVerticalTextStyle(cell)">
                                  {{cell.value}}
                                </div>
                              </td>
                            </ng-container>
                          </tr>
                        </tbody>
                      </table>
                    } @else {
                      <div class="spinner-container">No existen evidencias registradas</div>
                    }
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-col>
  </ion-row>

  <!-- Acciones -->
  <ion-row>
    <ion-col
      size-xs="12"
      size-sm="12"
      size-md="12"
      class="margin-col-start margin-col-end"
    >
      <div class="card basicCard flex" style="margin-top: 3vh">
        <ion-grid style="width: 100%">
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="6">
              <div class="container-title">Acciones</div>
            </ion-col>
            <ion-col
              size-xs="12"
              size-sm="12"
              size-md="6"
              style="text-align: end"
            >
              @if(user.id === 1 || (user.id !== 1 &&
              permissionService.checkSectionPermission(userByCompany, 8, 2))){
              <button
                pButton
                [loading]="loadingModal"
                icon="pi pi-plus"
                label="Añadir acción"
                (click)="showModalAddAction()"
              ></button>
              }</ion-col
            >
          </ion-row>

          <!-- No existen acciones -->
          @if(actionData.length === 0 &&
          permissionService.checkSectionPermission(userByCompany, 8, 1)){
          <div class="spinner-container">No existen acciones registradas</div>
          }

          <!-- Permiso de lectura de acciones a false -->
          @if(!permissionService.checkSectionPermission(userByCompany, 8, 1)){
          <div class="flex w-full justify-content-center align-items-center">
            No tiene permisos de lectura de acciones
          </div>
          } @else {
          <!--  -->
          @if(actionData){
          <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="12">
              <p-dataView
                #dv
                [value]="actionData"
                [rows]="3"
                [paginator]="true"
              >
                <ng-template pTemplate="list" let-actionData>
                  <ion-grid class="w-full">
                    @for(action of actionData; track table; let i = $index){
                    @if(user?.id === 1 || (user?.id !== 1 &&
                    permissionService.checkActionPermission(action, user, 1))){
                    <div class="card" style="margin-top: 3vh">
                      <ion-row class="row-fix">
                        <ion-col size-xs="12" size-sm="12" size-md="10">
                          <div class="">
                            <p-chip
                              class="custom-chip-danger p-1"
                              pTooltip="Identificador"
                              tooltipPosition="top"
                              label="{{ '#' + actionData[i].id }}"
                            ></p-chip>
                            <p-chip
                              class="custom-chip-primary p-1"
                              icon="pi pi-calendar"
                              pTooltip="Frecuencia"
                              tooltipPosition="top"
                              label="{{ actionData[i]['frequency']?.name }}"
                            ></p-chip>
                            <p-chip
                              class="custom-chip-secondary p-1"
                              icon="pi pi-user"
                              pTooltip="Responsable"
                              tooltipPosition="top"
                              label="{{ actionData[i]['employee']?.user?.name }}"
                            ></p-chip>
                            <p-chip
                              class="custom-chip-light p-1"
                              icon="pi pi-users"
                              pTooltip="Departamento"
                              tooltipPosition="top"
                              label="{{ actionData[i]['department']?.main_activity }}"
                            ></p-chip>
                            <p-chip
                              class="custom-chip-dark p-1"
                              icon="pi pi-calendar-times"
                              pTooltip="Fecha inicio"
                              tooltipPosition="top"
                              label="{{ (actionData[i].date | date: 'dd/MM/YYYY') }}"
                            ></p-chip>
                            <ng-container
                              *ngIf="actionData[i].assigned_request"
                            >
                              <p-chip
                                class="custom-chip-success p-1"
                                icon="pi pi-user"
                                pTooltip="Usuario no notificado"
                                tooltipPosition="top"
                                label="Asignada"
                              ></p-chip>
                            </ng-container>
                            <p-chip
                              class="custom-chip-info p-1"
                              pTooltip="{{ actionData[i].description }}"
                              tooltipPosition="top"
                              label="Descripción"
                            ></p-chip>
                            <p-chip
                              class="custom-chip-warning p-1"
                              pTooltip="{{ actionData[i].demand }}"
                              tooltipPosition="top"
                              label="Petición"
                            ></p-chip>
                          </div>
                        </ion-col>
                        <ion-col
                          size-xs="12"
                          size-sm="12"
                          size-md="2"
                          style="text-align: end"
                        >
                          @if(permissionService.checkSectionPermission(userByCompany,
                          10, 2)){
                          <button
                            pButton
                            icon="pi pi-plus"
                            label="Añadir evidencia"
                            (click)="showAddEvidenceDialog(actionData[i])"
                            class="m-1"
                          ></button>
                          }
                        </ion-col>
                      </ion-row>

                      <!-- Evidencias -->
                      <ion-row>
                        @if(action.evidences.length > 0 &&
                        permissionService.checkSectionPermission(userByCompany,
                        10, 1)){
                        <ion-col>
                          <p-dataView
                            #dv
                            [value]="action.evidences"
                            [rows]="5"
                            [paginator]="true"
                          >
                            <ng-template pTemplate="list" let-evidences>
                              @for(evidence of evidences; track evidence.id; let
                              idx = $index){
                              <p-accordion class="accordion-indicator">
                                <p-accordionTab>
                                  <ng-template pTemplate="header">
                                    {{ 'Evidencia con fecha ' + (evidence.date |
                                    date: 'dd/MM/YYYY') }}
                                    @if(evidence.evaluations.length > 0){
                                    <!-- status evaluacion -->
                                    @if(evidence.evaluations[0].efficacy === 0){
                                    <ion-icon
                                      name="clipboard-outline"
                                      class="evidence-orange"
                                      pTooltip="Evidencia evaluada como no eficaz"
                                    />
                                    } @else {
                                    <ion-icon
                                      name="clipboard-outline"
                                      class="evidence-green"
                                      pTooltip="Evidencia evaluada como eficaz"
                                    />
                                    } } @if(evidence.evaluations.length === 0){
                                    <ion-icon
                                      name="clipboard-outline"
                                      class="evidence-red"
                                      pTooltip="Evidencia no evaluada"
                                    />
                                    }
                                    <!-- Último usuario que ha trabajado en la evidencia -->
                                    <div style="margin-left: auto">
                                      <span style="font-weight: lighter">
                                        Creado por </span
                                      >{{evidence?.user?.name}}
                                    </div>
                                  </ng-template>
                                  <!-- check evidencia evaluada -->
                                  @if(evidence.evaluations.length > 0){
                                  <div class="container-list-values">
                                    <div class="left-section">
                                      <span>
                                        Fecha de subida:
                                        <strong>
                                          {{ evidence.created_at | date:
                                          'dd/MM/YYYY HH:mm' }}
                                        </strong>
                                      </span>
                                      <span>
                                        Fecha de evaluación:
                                        <strong>
                                          {{ evidence.evaluations[0].updated_at
                                          | date: 'dd/MM/YYYY HH:mm' }}
                                        </strong>
                                      </span>
                                    </div>
                                    <div class="right-section">
                                      <span
                                        >Evaluado por:
                                        <strong
                                          >{{ evidence.evaluations[0].user.name
                                          }}</strong
                                        ></span
                                      >
                                    </div>
                                  </div>
                                  } @else{
                                  <div class="evidence-noevaluate">
                                    Evidencia <strong>sin evaluar</strong>
                                  </div>
                                  }
                                  <!-- observaciones -->
                                  @if(evidence.observations){
                                  <div class="container-btn-total-values">
                                    <span
                                      ><b>Observaciones:</b>
                                      {{evidence.observations}}</span
                                    >
                                  </div>
                                  }
                                  <ion-row>
                                    <ion-col>
                                      <!--ver evidencia-->
                                      <p-button
                                        pTooltip="Ver Evidencia"
                                        icon="pi pi-eye"
                                        class="p-1"
                                        (click)="showViewEvidence(action.id, evidence.id)"
                                      />
                                      <!--ver documento evidencia-->
                                      @if(evidence.document){
                                      <p-button
                                        label="Documento adjunto"
                                        icon="pi pi-copy"
                                        class="ml-2"
                                        (click)="openDocument(evidence.document)"
                                      />
                                      }
                                      <!--Evaluar evidencia-->
                                      @if(this.permissionService.checkSectionPermission(userByCompany,
                                      11, 2) && evidence.evaluations.length ===
                                      0){
                                      <p-button
                                        label="Evaluar evidencia"
                                        class="ml-3 p-button-success"
                                        (click)="showEvaluateModal(action, evidence, evidence.evaluations.length)"
                                      />
                                      }
                                      <!--actualizar evaluacion evidencia-->
                                      @if(this.permissionService.checkSectionPermission(userByCompany,
                                      11, 3) && evidence.evaluations.length >
                                      0){
                                      <p-button
                                        label="Actualizar evaluación"
                                        class="ml-3 p-button-success"
                                        (click)="showEvaluateModal(action, evidence, evidence.evaluations.length)"
                                      />
                                      }
                                    </ion-col>
                                  </ion-row>
                                </p-accordionTab>
                              </p-accordion>
                              }
                            </ng-template>
                          </p-dataView>
                        </ion-col>
                        }
                      </ion-row>
                      <!-- Fin evidencias -->
                    </div>
                    }
                    <!--  -->
                    }
                  </ion-grid>
                </ng-template>
              </p-dataView>
            </ion-col>
          </ion-row>
          }
          <!--  -->
          }
        </ion-grid>
      </div>
    </ion-col>
  </ion-row>
  }
</ion-grid>

<!-- Modal de creación/edición de estado inicial -->
<p-dialog
  header="{{buttonUpdateInitialState ? 'Editar' : 'Añadir'}} estado inicial"
  [(visible)]="dialogFormInitialState"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '100vw', height: '100vh'}"
  (onHide)="handleHideInitialState()"
>
  @if(loadingInitialState){
  <div class="spinner-container-indicator" style="min-width: 350px">
    <ion-spinner name="dots"> </ion-spinner>
  </div>
  }

  <!-- Paso 1 initialState -->
  @if(step1InitialState || updatedInitialState){
  <form [formGroup]="formInitialState">
    <ion-grid class="p-5">
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Responsable</label>
          <div class="mt-1">
            <p-dropdown
              placeholder="Seleccione un empleado"
              formControlName="employee_id"
              [options]="itemsEmployees"
              optionLabel="name"
              optionValue="id"
              (onChange)="checkUserSelected($event)"
            />
          </div>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Fecha</label>
          <div class="mt-1">
            <p-calendar
              appendTo="body"
              class="calendar-form"
              formControlName="date"
              dateFormat="dd/mm/yy"
            />
          </div>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Descripción</label>
          <textarea
            class="w-full mb-3 mt-1"
            pInputTextarea
            rows="4"
            formControlName="description"
          ></textarea>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Observaciones</label>
          <textarea
            class="w-full mb-3 mt-1"
            pInputTextarea
            rows="4"
            formControlName="observations"
          ></textarea>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
  }

  <!-- Paso 2 initialState -->
  @if(step2InitialState || updatedInitialState){
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <div class="disclaimer-container mb-1">
          <label>Datos del estado inicial</label>
          <small
            ><strong
              >* Haga scroll horizontal y/o vertical para ir visualizando la
              tabla al completo</strong
            ></small
          >
        </div>
        @if(initialStateDataJSON){
        <table>
          <tbody>
            <tr *ngFor="let row of initialStateDataJSON.rows">
              <ng-container *ngFor="let cell of row.cells">
                <td
                  [attr.colspan]="cell.colspan"
                  [attr.rowspan]="cell.rowspan"
                  [class.vertical-text]="cell['vertical-text']"
                  [ngStyle]="getCellStyle(cell)"
                >
                  <div [ngStyle]="getVerticalTextStyle(cell)">
                    @if(cell.input){
                    <input
                      [(ngModel)]="cell.value"
                      pInputText
                      [ngStyle]="{ 'width': '100%', 'padding': '5px' }"
                      [inputStyle]="{ width: '100%' }"
                    />
                    } @else { {{ cell.value }} }
                  </div>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
        }
      </ion-col>
    </ion-row>
  </ion-grid>
  }
  <ng-template pTemplate="footer">
    <!--  -->
    @if(buttonStep1InitialState){
    <div class="footer-btn">
      <p-button
        label="Siguiente"
        [loading]="loadingInitialState"
        (click)="submitInsertInitialStateStep1()"
      ></p-button>
    </div>
    }
    <!--  -->
    @if(buttonStep2InitialState){
    <div class="footer-btn">
      <p-button
        label="Guardar"
        [loading]="loadingInitialState"
        (click)="submitInsertInitialStateStep2()"
      ></p-button>
    </div>
    }
    <!--  -->
    @if(buttonUpdateInitialState){
    <div class="footer-btn">
      <p-button
        label="Actualizar"
        [loading]="loadingInitialState"
        (click)="submitUpdateDataInitialState()"
      ></p-button>
    </div>
    }
  </ng-template>
</p-dialog>

<!-- Modal visualizar estado inicial -->
<p-dialog
  header="Ver estado inicial con fecha {{ initialStateData?.date | date: 'dd/MM/YYYY' }}"
  [(visible)]="dialogViewInitialState"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '100vw', height: '100vh'}"
  (onHide)="handleHideViewInitialState()"
>
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        @if(initialStateDataJSON){
        <table>
          <tbody>
            <tr *ngFor="let row of initialStateDataJSON.rows">
              <ng-container *ngFor="let cell of row.cells">
                <td
                  [attr.colspan]="cell.colspan"
                  [attr.rowspan]="cell.rowspan"
                  [class.vertical-text]="cell['vertical-text']"
                  [ngStyle]="getCellStyle(cell)"
                >
                  <div [ngStyle]="getVerticalTextStyle(cell)">
                    {{cell.value}}
                  </div>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
        }
      </ion-col>
    </ion-row>
  </ion-grid>
</p-dialog>

<!-- Modal de creación/edición de objetivo -->
<p-dialog
  header="{{buttonUpdateObjective ? 'Editar' : 'Añadir'}} objetivo {{buttonUpdateObjective ? 'con fecha ' + (objectiveCurrentSelect?.date | date: 'dd/MM/YYYY') : ''}}"
  [(visible)]="dialogFormObjective"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '100vw', height: '100vh'}"
  (onHide)="handleHideObjective()"
>
  @if(loadingObjective){
  <div class="spinner-container-indicator" style="min-width: 350px">
    <ion-spinner name="dots"> </ion-spinner>
  </div>
  }

  <!-- Objective -->
  @if(saveObjective || updatedObjective){
  <form [formGroup]="formObjective">
    <ion-grid class="p-5">
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Fecha *</label>
          <div class="mt-1">
            <p-calendar
              appendTo="body"
              class="calendar-form"
              formControlName="date"
            />
          </div>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="formObjective.get('date')!.errors && formObjective.get('date')!.touched && formObjective.get('date')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Observaciones</label>
          <textarea
            class="w-full mb-3 mt-1"
            pInputTextarea
            formControlName="observations"
          ></textarea>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>

  <ion-grid class="p-5">
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <div class="disclaimer-container mb-1">
          <label>Datos del objetivo</label>
          <small
            ><strong
              >* Haga scroll horizontal y/o vertical para ir visualizando la
              tabla al completo</strong
            ></small
          >
        </div>
        @if(objectiveDataJSON){
        <table>
          <tbody>
            <tr *ngFor="let row of objectiveDataJSON.rows">
              <ng-container *ngFor="let cell of row.cells">
                <td
                  [attr.colspan]="cell.colspan"
                  [attr.rowspan]="cell.rowspan"
                  [class.vertical-text]="cell['vertical-text']"
                  [ngStyle]="getCellStyle(cell)"
                >
                  <div [ngStyle]="getVerticalTextStyle(cell)">
                    @if(cell.input){
                    <input
                      [(ngModel)]="cell.value"
                      pInputText
                      [ngStyle]="{ 'width': '100%', 'padding': '5px' }"
                      [inputStyle]="{ width: '100%' }"
                    />
                    } @else { {{ cell.value }} }
                  </div>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
        }
      </ion-col>
    </ion-row>
  </ion-grid>
  }
  <ng-template pTemplate="footer">
    @if(buttonSaveObjective){
    <div class="footer-btn">
      <p-button
        label="Guardar"
        [loading]="loadingObjective"
        (click)="submitInsertObjective()"
      ></p-button>
    </div>
    } @if(buttonUpdateObjective){
    <div class="footer-btn">
      <p-button
        label="Actualizar"
        [loading]="loadingObjective"
        (click)="submitUpdateDataObjective()"
      ></p-button>
    </div>
    }
  </ng-template>
</p-dialog>

<!-- Modal visualizar objetivo -->
<p-dialog
  header="Ver objetivo con fecha {{ objectiveCurrentSelect?.date | date: 'dd/MM/YYYY' }}"
  [(visible)]="dialogViewObjective"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '100vw', height: '100vh'}"
  (onHide)="handleHideViewObjective()"
>
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        @if(objectiveDataJSON){
        <table>
          <tbody>
            <tr *ngFor="let row of objectiveDataJSON.rows">
              <ng-container *ngFor="let cell of row.cells">
                <td
                  [attr.colspan]="cell.colspan"
                  [attr.rowspan]="cell.rowspan"
                  [class.vertical-text]="cell['vertical-text']"
                  [ngStyle]="getCellStyle(cell)"
                >
                  <div [ngStyle]="getVerticalTextStyle(cell)">
                    {{cell.value}}
                  </div>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
        }
      </ion-col>
    </ion-row>
  </ion-grid>
</p-dialog>

<!-- Modal para formulario de acción -->
<p-dialog
  header="Añadir acción"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [(visible)]="visible"
  [style]="{ width: '70vw' }"
  (onHide)="closeModalAction()"
>
  @if(!loadingSaveAction){
  <form [formGroup]="formAddAction">
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Empleado</label>
          <div class="mt-1">
            <p-dropdown
              placeholder="Seleccione un empleado"
              formControlName="employee_id"
              [options]="itemsEmployees"
              optionLabel="name"
              optionValue="id"
              (onChange)="checkUserSelected($event)"
            />
          </div>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="formAddAction.get('employee_id')!.errors && formAddAction.get('employee_id')!.touched && formAddAction.get('employee_id')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        @if(loadingCheckEmployee){
        <div class="spinner-container">
          <ion-spinner name="dots"> </ion-spinner>
          <ion-label>Comprobando permisos en secciones ...</ion-label>
        </div>
        } @else{
        <!--  -->
        @if(checkUserByCompany){
        <!--  -->
        @if(checkPermissionsByUser){
        <!---Tiene permisos-->
        } @else{
        <!--  -->
        @if(actionEmployeSelected){
        <!---No tiene permisos-->
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <div class="text-center">
            <div class="m-4">
              <p>
                El usuario no tiene permisos de acceso a secciones en la empresa
                seleccionada.
              </p>
              <p>
                Vaya al panel de usuarios y gestione los permisos de acceso a
                secciones para el usuario seleccionado.
              </p>
            </div>
          </div>
        </ion-col>
        }
        <!--  -->
        }
        <!--  -->
        }@else{
        <!--  -->
        @if(actionEmployeSelected){
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <div class="text-center">
            <div class="m-4">
              <p>El usuario no está vinculado con esta empresa.</p>
              <p>
                Vaya al panel de usuarios y vincule el usuario con la empresa
                para poder asignarle permisos.
              </p>
            </div>
          </div>
        </ion-col>
        }
        <!--  -->
        }
        <!--  -->
        }
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Frecuencia</label>
          <div class="mt-1">
            <p-dropdown
              placeholder="Seleccione frecuencia"
              formControlName="frequency_id"
              [options]="itemsFrequencies"
              optionLabel="name"
              optionValue="id"
            />
          </div>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="formAddAction.get('frequency_id')!.errors && formAddAction.get('frequency_id')!.touched && formAddAction.get('frequency_id')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Descripción</label>
          <textarea
            class="w-full mb-3 mt-1"
            pInputTextarea
            rows="4"
            formControlName="description"
          ></textarea>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Petición</label>
          <textarea
            class="w-full mb-3 mt-1"
            pInputTextarea
            rows="4"
            formControlName="demand"
          ></textarea>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Fecha</label>
          <div class="mt-1">
            <p-calendar
              appendTo="body"
              class="calendar-form"
              formControlName="date"
              dateFormat="dd/mm/yy"
            />
          </div>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="formAddAction.get('date')!.errors && formAddAction.get('date')!.touched && formAddAction.get('date')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label for="required_document">*Documento obligatorio</label>
          <div class="mt-1 p-fluid">
            <p-selectButton
              formControlName="required_document"
              [options]="yesOrNo"
              optionValue="value"
              optionLabel="label"
              [ngStyle]="{ 'width': '100%', 'padding': '5px' }"
            />
          </div>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="formAddAction.get('required_document')!.errors && formAddAction.get('required_document')!.touched && formAddAction.get('required_document')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
      </ion-row>
    </ion-grid>
    <div>
      <small
        >* Guardar esta acción permite al responsable acceder a reportar
        evidencias.</small
      >
    </div>
  </form>
  }

  <!--  -->
  @if(loadingSaveAction){
  <div class="spinner-container-indicator">
    <ion-spinner name="dots"> </ion-spinner>
    <ion-label for="">Generando acción, por favor espere...</ion-label>
  </div>
  }

  <ng-template pTemplate="footer">
    <p-button label="Cancelar" severity="secondary" (click)="visible = false" />
    <p-button
      label="Guardar"
      (click)="saveAddAction()"
      [loading]="loadingSaveAction"
      [disabled]="formAddAction.invalid"
    />
  </ng-template>
</p-dialog>

<!-- Modal de creación de evidencia -->
<p-dialog
  header="Añadir evidencia"
  [(visible)]="dialogFormEvidence"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="handleHideEvidence()"
  [style]="{ width: '100vw', height: '100vh'}"
>
  @if(loadingEvidence){
  <div class="spinner-container-indicator" style="min-width: 350px">
    <ion-spinner name="dots"> </ion-spinner>
  </div>
  }

  <!-- Evidence -->
  @if(saveEvidence){
  <form [formGroup]="formEvidence">
    <ion-grid class="p-5">
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Fecha</label>
          <div class="mt-1">
            <p-calendar
              appendTo="body"
              class="calendar-form"
              formControlName="date"
            /></div
        ></ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Observaciones</label>
          <textarea
            class="w-full mb-3 mt-1"
            pInputTextarea
            formControlName="observations"
          ></textarea>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          @if(actionSelected && actionSelected.required_document){
          <label>*Documento de evidencia</label>
          } @else {
          <label>Documento de evidencia</label>
          }
          <p-fileUpload
            name="file"
            chooseLabel="Seleccionar archivo"
            [customUpload]="true"
            [showUploadButton]="false"
            (onSelect)="onFileSelect($event)"
            (onRemove)="deleteFileSelected($event)"
          ></p-fileUpload>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>

  <ion-grid class="p-5">
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <div class="disclaimer-container mb-1">
          <label>Datos de la evidencia</label>
          <small
            ><strong
              >* Haga scroll horizontal y/o vertical para ir visualizando la
              tabla al completo</strong
            ></small
          >
        </div>
        @if(evidenceDataJSON){
        <table>
          <tbody>
            <tr *ngFor="let row of evidenceDataJSON.rows">
              <ng-container *ngFor="let cell of row.cells">
                <td
                  [attr.colspan]="cell.colspan"
                  [attr.rowspan]="cell.rowspan"
                  [ngStyle]="getCellStyle(cell)"
                  [class.vertical-text]="cell['vertical-text']"
                >
                  <div [ngStyle]="getVerticalTextStyle(cell)">
                    @if(cell.input){
                    <input
                      [(ngModel)]="cell.value"
                      pInputText
                      [ngStyle]="{ 'width': '100%', 'padding': '5px' }"
                      [inputStyle]="{ width: '100%' }"
                    />
                    } @else { {{ cell.value }} }
                  </div>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
        }
      </ion-col>
    </ion-row>
  </ion-grid>
  }
  <ng-template pTemplate="footer">
    <!--  -->
    @if(buttonSaveEvidence){
    <div class="footer-btn">
      <p-button
        label="Guardar"
        [loading]="loadingEvidence"
        (click)="submitInsertEvidence()"
      ></p-button>
    </div>
    }
  </ng-template>
</p-dialog>

<!-- Modal visualizar evidencia -->
<p-dialog
  header="Ver evidencia con fecha {{ evidenceCurrentSelect?.date | date: 'dd/MM/YYYY' }}"
  [(visible)]="dialogViewEvidence"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '100vw', height: '100vh'}"
  (onHide)="handleHideViewEvidence()"
>
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12" class="margin-col-start">
        @if(evidenceDataJSON){
        <table>
          <tbody>
            <tr *ngFor="let row of evidenceDataJSON.rows">
              <ng-container *ngFor="let cell of row.cells">
                <td
                  [attr.colspan]="cell.colspan"
                  [attr.rowspan]="cell.rowspan"
                  [class.vertical-text]="cell['vertical-text']"
                  [ngStyle]="getCellStyle(cell)"
                >
                  <div [ngStyle]="getVerticalTextStyle(cell)">
                    {{cell.value}}
                  </div>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
        }
      </ion-col>
    </ion-row>
  </ion-grid>
</p-dialog>

<!-- Modal para evaluar una evidencia -->
<p-dialog
  header="Evaluar evidencia con fecha {{ evidenceSearched?.date | date: 'dd/MM/YYYY' }}"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [(visible)]="showEvaluateDialog"
  [style]="{ width: '40rem' }"
  (onHide)="formEvaluate.reset()"
>
  @if(!loadingModal){
  <form [formGroup]="formEvaluate">
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Observaciones</label>
          <textarea
            class="w-full mb-3 mt-1"
            pInputTextarea
            formControlName="observations"
          ></textarea>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <div class="toggle-container">
            <label>¿Es correcto?</label>
            <p-toggleButton
              formControlName="efficacy"
              onLabel="Sí"
              offLabel="No"
            />
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
  } @if(loadingModal){
  <div class="spinner-container-indicator">
    <ion-spinner name="dots"> </ion-spinner>
    <ion-label for="">Evaluando evidencia, por favor espere...</ion-label>
  </div>
  }

  <ng-template pTemplate="footer">
    <p-button
      label="Guardar"
      [loading]="loadingModal"
      (click)="sendEvidenceEvaluation()"
    >
    </p-button>
  </ng-template>
</p-dialog>

<!-- Modal para editar descripción de indicador -->
<p-dialog
  [(visible)]="dialogDescriptionUpdate"
  header="Descripción del indicador"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '40vw' }"
  (onHide)="handleCloseUpdateDescriptionDialog()"
>
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <label for="">
          Descripción del indicador
          <span>({{ characterCount }}/500 caracteres)</span>
        </label>
        <div class="mb-1">
          <textarea
            class="w-full mb-3 mt-1"
            pInputTextarea
            rows="4"
            maxlength="500"
            [(ngModel)]="indicatorDescription"
            (input)="updateCharacterCount()"
          ></textarea>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ng-template pTemplate="footer">
    <p-button label="Guardar" [loading]="loadingType" (click)="updateIndicatorData()"> </p-button>
  </ng-template>
</p-dialog>

